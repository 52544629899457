.MuiDayCalendar-header {
    background-color: #E8EDF5;
    margin: 0px 20px;
}
.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.css-riqzx5-MuiButtonBase-root-MuiPickersDay-root {
    background-color: #BC9E6E;
    border-radius: 0px;
}
.MuiTouchRipple-root.css-r3djoj-MuiTouchRipple-root {
    border-radius: 0px;
}
.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.css-riqzx5-MuiButtonBase-root-MuiPickersDay-root {
    border-radius: 0px;
}
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.MuiPickersDay-today.css-1wlomq7-MuiButtonBase-root-MuiPickersDay-root {
    border-radius: 0px;
}